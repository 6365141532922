export default function GraphToolbarButtonContainer({
  children,
  additionalClasses,
}) {
  return (
    <span
      className={`mx-1 my-1${additionalClasses ? ` ${additionalClasses}` : ""}`}
    >
      {children}
    </span>
  );
}
