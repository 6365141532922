import { useState } from "react";

import "../../css/style.css";

import CurrentReportNavIcon from "../../assets/NavIcons/CurrentReportNavIcon";
import NewReportNavIcon from "../../assets/NavIcons/NewReportNavIcon";
import OpenReportNavIcon from "../../assets/NavIcons/OpenReportNavIcon";

import CurrentReport from "../CurrentReport/CurrentReport";
import NewReport from "../NewReport/NewReport";
import OpenReport from "../OpenReport/OpenReport";

import { Link, Routes, Route } from "react-router-dom";
import { Nav, NavItem, NavLink } from "react-bootstrap";
import PatientList from "../Patient/PatientList";
import CreateNewPatient from "../Patient/CreateNewPatient";

export default function Dashboard() {
  const [currentReportId, setCurrentReportId] = useState("");
  const [activeKey, setActiveKey] = useState("open-report");

  const handleSetCurrentReportId = (reportId) => {
    setCurrentReportId(reportId);
  };

  return (
    <div id="dashboard-container" className="d-flex flex-column flex-grow-1">
      <Nav id="nav-container" variant="tabs" activeKey={activeKey}>
        {/* <NavItem>
          <NavLink eventKey={"patients"}>
            <Link
              to="patients"
              style={{ textDecoration: "none" }}
              onClick={() => setActiveKey("patients")}
            >
              <div>
                {" "}
                <span className="pe-1">
                  <OpenReportNavIcon />
                </span>
                Patients
              </div>
            </Link>
          </NavLink>
        </NavItem> */}
        <NavItem>
          <NavLink eventKey={"create-new-patient"}>
            <Link
              to="create-new-patient"
              style={{ textDecoration: "none" }}
              onClick={() => setActiveKey("create-new-patient")}
            >
              <div>
                {" "}
                <span className="pe-1">
                  <OpenReportNavIcon />
                </span>
                Create New Patient
              </div>
            </Link>
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink eventKey={"open-report"}>
            <Link
              to="open-report"
              style={{ textDecoration: "none" }}
              onClick={() => setActiveKey("open-report")}
            >
              <div>
                <span className="pe-1">
                  <OpenReportNavIcon />
                </span>
                Open Report
              </div>
            </Link>
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink eventKey={"new-report"}>
            <Link
              to="new-report"
              style={{ textDecoration: "none" }}
              onClick={() => setActiveKey("new-report")}
            >
              <div>
                <span className="pe-1">
                  <NewReportNavIcon />
                </span>
                New Report
              </div>
            </Link>
          </NavLink>
        </NavItem>
        {currentReportId !== "" && (
          <NavItem>
            <NavLink eventKey={"current-report"}>
              <Link
                to="current-report"
                state={{ reportId: currentReportId }}
                style={{ textDecoration: "none" }}
                onClick={() => setActiveKey("current-report")}
              >
                <div>
                  <span className="pe-1">
                    <CurrentReportNavIcon />
                  </span>
                  Current Report
                </div>
              </Link>
            </NavLink>
          </NavItem>
        )}
      </Nav>
      <div
        id="dashboard-page-content-container"
        className="d-flex justify-content-center flex-grow-1"
      >
        <Routes>
          <Route path="patients" element={<PatientList />} />
          <Route path="create-new-patient" element={<CreateNewPatient />} />
          <Route path="current-report" element={<CurrentReport />} />
          <Route
            path="new-report"
            element={
              <NewReport
                setDashboardReportId={handleSetCurrentReportId}
                setActiveNavKey={setActiveKey}
              />
            }
          />
          <Route
            path="open-report"
            element={
              <OpenReport
                setDashboardReportId={handleSetCurrentReportId}
                setActiveNavKey={setActiveKey}
                currentReportId={currentReportId}
              />
            }
          />
        </Routes>
      </div>
    </div>
  );
}
