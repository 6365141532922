import Plot from "react-plotly.js";
import jstat from "jstat";

import {
  getNormalDistributionPlotData,
  convertZToValue,
  getNumberFormatByType,
  getMeanFromType,
  getStdDevFromType,
  getTypeTextFromType,
} from "../../../helpers/statsHelperFunctions";
import { useEffect, useState } from "react";
import DemoProgressMonitorGraph from "./DemoProgressMonitorGraph";

function generateScoreGraphData(scores, plotUnits) {
  if (scores === undefined) {
    return;
  }

  const sortedScores = sortScores(scores);

  const scoreGraphData = sortedScores.map((score) => {
    return {
      type: "scatter",
      mode: "markers",
      name: score.name === "" ? "Score" : score.name,
      x: [convertZToValue(score.z, plotUnits)],
      y: [
        jstat.normal.pdf(
          convertZToValue(score.z, plotUnits),
          getMeanFromType(plotUnits),
          getStdDevFromType(plotUnits)
        ),
      ],
      marker: {
        symbol: score.markerStyle,
        color: score.markerColor,
        size: 14,
        line: { width: score.markerStyle < 100 ? 1 : 2 },
      },
      hovertemplate:
        getNumberFormatByType(
          convertZToValue(score.z, score.type),
          getTypeTextFromType(score.type)
        ).value +
        " (" +
        score.type +
        ")",
    };
  });
  return scoreGraphData;
}

function sortScores(scores) {
  const parentScores = scores.filter(
    (score) => !score.isSubScore && score.visible
  );
  let sortedScores = [];
  parentScores.forEach((parentScore) => {
    sortedScores = [...sortedScores, parentScore];
    const childScores = scores.filter(
      (score) => score.parentScoreId === parentScore.id && score.visible
    );
    if (childScores.length > 0)
      sortedScores = [...sortedScores, ...childScores];
  });
  return sortedScores;
}

export default function GraphPlot({ report, plotUnits, onPlotUpdate }) {
  const [data, setData] = useState([]);

  const normalDistributionPlotData = getNormalDistributionPlotData(plotUnits);
  useEffect(() => {
    if (report === undefined) {
      return;
    }

    setData([
      {
        x: normalDistributionPlotData.x,
        y: normalDistributionPlotData.y,
        showlegend: false,
        type: "scatter",
        mode: "spline",
        marker: { color: "black" },
        hoverinfo: "skip",
      },
      ...generateScoreGraphData(report.scores, plotUnits),
    ]);
  }, [report, plotUnits]);

  const handlePlotUpdate = (figure, graphDiv) => {
    onPlotUpdate(graphDiv);
  };

  return (
    <>
      {report !== undefined && (
        <Plot
          data={data}
          layout={{
            title: report.name,
            border: { width: 1, color: "black", style: "solid" },
            yaxis: {
              showticklabels: false,
              range: [
                -0.1 *
                  jstat.normal.pdf(
                    getMeanFromType(plotUnits),
                    getMeanFromType(plotUnits),
                    getStdDevFromType(plotUnits)
                  ),
                1.1 *
                  jstat.normal.pdf(
                    getMeanFromType(plotUnits),
                    getMeanFromType(plotUnits),
                    getStdDevFromType(plotUnits)
                  ),
              ],
              fixedrange: true,
            },
            xaxis: {
              title: getTypeTextFromType(plotUnits),
              nticks: 10,
              zeroline: false,
              range: [
                convertZToValue(-4, plotUnits),
                convertZToValue(4, plotUnits),
              ],
              fixedrange: true,
              standoff: 5,
            },
            paper_bgcolor: "#fff",
            plot_bgcolor: "#fff",
            legend: {
              x: 1,
              xanchor: "right",
              y: 1,
              itemclick: false,
              bgcolor: "rgba(0, 0, 0, 0)",
            },
          }}
          useResizeHandler={true}
          config={{ displayModeBar: false }}
          className="flex-grow-1 flex-shrink-1"
          // style={{ width: "100%", height: "100%" }}
          onUpdate={handlePlotUpdate}
        />
      )}
    </>
  );
}
