import Plot from "react-plotly.js";
import jstat from "jstat";

import {
  getNormalDistributionPlotData,
  convertZToValue,
  getNumberFormatByType,
  getTypeTextFromType,
} from "../../../helpers/statsHelperFunctions";
import { useEffect, useState } from "react";

function generateScoreGraphData(scores, plotUnits) {
  if (scores === undefined) {
    return;
  }

  const sortedScores = sortScores(scores);

  let scoreGroups = [];
  let currentScoreGroup = [];
  sortedScores.forEach((score) => {
    if (!score.isSubScore) {
      if (currentScoreGroup.length > 0) {
        scoreGroups.push(currentScoreGroup);
        currentScoreGroup = [];
      }
    }
    currentScoreGroup.push(score);
  });
  scoreGroups.push(currentScoreGroup);

  const scoreGraphData = scoreGroups.map((scoreGroup, index) => {
    const reversedScoreGroup = scoreGroup.reverse();
    return {
      name: reversedScoreGroup.map((score) => score.name),
      text: reversedScoreGroup.map(
        (score) =>
          getNumberFormatByType(convertZToValue(score.z, plotUnits), plotUnits)
            .value
      ),
      type: "bar",
      orientation: "h",
      x: reversedScoreGroup.map(
        (score) =>
          getNumberFormatByType(convertZToValue(score.z, plotUnits), plotUnits)
            .value
      ),
      y: reversedScoreGroup.map((score) => score.name),
      marker: {
        opacity: reversedScoreGroup.map((score) =>
          score.isSubScore ? 0.25 : 1.0
        ),
        color: reversedScoreGroup.map((score) => score.markerColor),
      },
      line: { width: 1, color: "black" },
      hoverinfo: "skip",
    };
  });

  return scoreGraphData.reverse();
}

function sortScores(scores) {
  const parentScores = scores.filter(
    (score) => !score.isSubScore && score.visible
  );
  let sortedScores = [];
  parentScores.forEach((parentScore) => {
    sortedScores = [...sortedScores, parentScore];
    const childScores = scores.filter(
      (score) => score.parentScoreId === parentScore.id && score.visible
    );
    if (childScores.length > 0)
      sortedScores = [...sortedScores, ...childScores];
  });
  return sortedScores;
}

export default function GraphBarChart({ report, plotUnits, onPlotUpdate }) {
  const [data, setData] = useState([]);

  const normalDistributionPlotData = getNormalDistributionPlotData(plotUnits);
  useEffect(() => {
    if (report === undefined) {
      return;
    }

    setData([...generateScoreGraphData(report.scores, plotUnits)]);
  }, [report, plotUnits]);

  const handlePlotUpdate = (figure, graphDiv) => {
    onPlotUpdate(graphDiv);
  };

  return (
    <>
      {report !== undefined && (
        <Plot
          data={data}
          layout={{
            title: report.name,
            border: { width: 1, color: "black", style: "solid" },
            showlegend: false,
            xaxis: {
              showticklabels: true,
              title: getTypeTextFromType(plotUnits),
            },
            yaxis: {
              zeroline: false,
              // standoff: 20,
              automargin: true,
            },
            paper_bgcolor: "#fff",
            plot_bgcolor: "#fff",
            legend: {
              xanchor: "left",
              itemclick: false,
              bgcolor: "rgba(0, 0, 0, 0)",
              traceorder: "reversed",
            },
            fixedrange: true,
            barmode: "group",
            bargap: 0,
            bargroupgap: 0.1,
          }}
          useResizeHandler={true}
          config={{ displayModeBar: false }}
          className="ms-5"
          onUpdate={handlePlotUpdate}
        />
      )}
    </>
  );
}
