import React from "react";

export default function CurrentReportNavIcon({
  backgroundColor = "rgba(0,0,0,0)",
  iconColor = "#213231",
}) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill={backgroundColor}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18 20C17.45 20 16.979 19.804 16.587 19.412C16.195 19.02 15.9993 18.5493 16 18V15C16 14.45 16.196 13.979 16.588 13.587C16.98 13.195 17.4507 12.9993 18 13C18.55 13 19.021 13.196 19.413 13.588C19.805 13.98 20.0007 14.4507 20 15V18C20 18.55 19.804 19.021 19.412 19.413C19.02 19.805 18.5493 20.0007 18 20ZM12 20C11.45 20 10.979 19.804 10.587 19.412C10.195 19.02 9.99934 18.5493 10 18V6C10 5.45 10.196 4.979 10.588 4.587C10.98 4.195 11.4507 3.99934 12 4C12.55 4 13.021 4.196 13.413 4.588C13.805 4.98 14.0007 5.45067 14 6V18C14 18.55 13.804 19.021 13.412 19.413C13.02 19.805 12.5493 20.0007 12 20ZM6 20C5.45 20 4.979 19.804 4.587 19.412C4.195 19.02 3.99934 18.5493 4 18V11C4 10.45 4.196 9.979 4.588 9.587C4.98 9.195 5.45067 8.99934 6 9C6.55 9 7.021 9.196 7.413 9.588C7.805 9.98 8.00067 10.4507 8 11V18C8 18.55 7.804 19.021 7.412 19.413C7.02 19.805 6.54934 20.0007 6 20Z"
        fill={iconColor}
      />
    </svg>
  );
}
