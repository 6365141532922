import { useRef, useState } from "react";
import {
  Button,
  FormControl,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
} from "react-bootstrap";

export default function useInputModal() {
  const [isModalVisibible, setIsModalVisible] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalText, setModalText] = useState("");
  const [inputValue, setInputValue] = useState("");
  const modalCb = useRef(() => {});

  const modal = (
    <Modal
      show={isModalVisibible}
      onHide={() => setIsModalVisible(false)}
      backdrop="static"
    >
      <ModalHeader closeButton>
        <ModalTitle>{modalTitle}</ModalTitle>
      </ModalHeader>
      <ModalBody>
        <div className="d-flex flex-column">
          <div>{modalText}</div>
          <div>
            <FormControl
              type="text"
              value={inputValue}
              autoFocus={true}
              onChange={(e) => setInputValue(e.target.value)}
              onFocus={(e) => e.target.select()}
            ></FormControl>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button
          variant="primary"
          onClick={() => {
            modalCb.current(inputValue);
            setIsModalVisible(false);
          }}
        >
          OK
        </Button>
      </ModalFooter>
    </Modal>
  );

  const showModal = (title, text, defaultValue, cb = (value) => {}) => {
    modalCb.current = cb;
    setModalTitle(title);
    setModalText(text);
    setInputValue(defaultValue);
    setIsModalVisible(true);
  };

  return [modal, showModal];
}
