import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { Button, FormCheck } from "react-bootstrap";

function generateReportRowData(report) {
  const date = new Date(report.createdOn);
  const dateString = `${date.toLocaleDateString()} - ${date.toLocaleTimeString()}`;
  return [report.name, dateString];
}

export default function ReportTableDataRow({
  report,
  deleteReport,
  openReport,
  checkRow,
}) {
  const reportRowData = generateReportRowData(report);
  const [rowChecked, setRowChecked] = useState(false);

  const handleRowCheck = (e) => {
    const isChecked = e.target.checked;
    checkRow(isChecked, report.id);
    setRowChecked(isChecked);
  };

  return (
    <tr className={rowChecked ? "table-primary" : ""}>
      <td className="align-middle text-center">
        <FormCheck onChange={handleRowCheck} />
      </td>
      {reportRowData.map((dataItem, index) => {
        return (
          <td
            key={index}
            role="button"
            className="align-middle"
            onClick={() => openReport(report.id)}
          >
            {dataItem}
          </td>
        );
      })}
      <td className="align-middle text-center">
        <Button
          variant="danger"
          onClick={(e) => {
            deleteReport(report.id);
          }}
        >
          <FontAwesomeIcon icon="fa-regular fa-trash-can" />
        </Button>
      </td>
    </tr>
  );
}
