import {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest,
} from "../api/apiHelpers";

const PATIENT_URL = `${process.env.REACT_APP_API_URL}/patient`;

export async function getPatientList(auth) {
  const res = await auth.fetchWithAuth(PATIENT_URL, getRequest(null));

  if (!res.ok) {
    throw res;
  }

  const patientList = await res.json();
  return patientList;
}

export async function getPatient(id, auth) {
  const res = await auth.fetchWithAuth(
    `${PATIENT_URL}/${id}`,
    getRequest(null)
  );

  if (!res.ok) {
    throw res;
  }

  const patient = await res.json();
  return patient;
}

export async function createPatient(
  {
    firstName,
    middleName,
    lastName,
    dob,
    sex,
    school,
    grade,
    providerPatientId,
  },
  auth
) {
  const res = await auth.fetchWithAuth(
    PATIENT_URL,
    postRequest({
      firstName,
      middleName,
      lastName,
      dob,
      sex,
      school,
      grade,
      providerPatientId,
    })
  );

  if (!res.ok) {
    throw res;
  }

  const newPatient = await res.json();
  return newPatient.id;
}

export async function updatePatient(updatedPatient, auth) {
  if (!updatedPatient) {
    return;
  }

  const res = await auth.fetchWithAuth(PATIENT_URL, putRequest(updatedPatient));

  if (!res.ok) {
    throw res;
  }
}

export async function deletePatient(id, auth) {
  const res = await auth.fetchWithAuth(PATIENT_URL, deleteRequest({ id }));

  if (!res.ok) {
    throw res;
  }
}
