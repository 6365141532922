import { useState } from "react";

import { toImage, downloadImage } from "plotly.js/dist/plotly";

import { useAuth } from "../../../hooks/useAuth";

import GraphPlot from "./GraphPlot";
import GraphToolbar from "./GraphToolbar";
import GraphBarChart from "./GraphBarChart";
import handleGenerateReportPdf from "../../../api/generateReportPdf";
import { createTemplate } from "../../../api/template";
import DemoProgressMonitorGraph from "./DemoProgressMonitorGraph";
import useInputModal from "../../../hooks/useInputModal";

export const SCATTER_PLOT_STYLE = "scatter";
export const BAR_PLOT_STYLE = "bar";

export default function Graph({ report }) {
  const { auth } = useAuth();
  const [modal, showModal] = useInputModal();

  const [plot, setPlot] = useState();
  const [isReportGenerating, setIsReportGenerating] = useState(false);
  const [plotUnits, setPlotUnits] = useState("Standard");
  const [plotStyle, setPlotStyle] = useState(SCATTER_PLOT_STYLE);

  const handlePlotUpdate = (graphDiv) => {
    setPlot(graphDiv);
  };

  const handlePlotStyleChange = (e) => {
    setPlotStyle(e.target.value);
  };

  const handleSavePlotClick = async () => {
    if (plot) {
      const image = await downloadImage(plot, {
        format: "png",
        width: 900,
        height: 700,
      });
    }
  };

  const handleGenerateReportPdfClick = async () => {
    if (plot) {
      setIsReportGenerating(true);
      const image = await toImage(plot, {
        format: "png",
        width: 900,
        height: 700,
      });
      const res = await handleGenerateReportPdf(report, image, auth);
      if (res.ok) {
        const buffer = await res.arrayBuffer();
        const blob = new Blob([buffer], { type: "application/pdf" });
        const fileURL = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = fileURL;
        link.download = `${report.name}.pdf`;
        link.click();
      }
    }
    setIsReportGenerating(false);
  };

  const handleSaveReportTemplate = () => {
    showModal(
      "Create Template",
      "Template name",
      report.name,
      createTemplateCb
    );
  };

  const createTemplateCb = async (name) => {
    console.log(name);
    await createTemplate(report.id, name, auth);
  };

  const handlePlotUnitsChange = (e) => {
    const selectedUnit = e.target.value;
    const newPlotUnits = selectedUnit === "t-Score" ? "t" : selectedUnit;
    setPlotUnits(newPlotUnits);
  };

  {
    return (
      <>
        <div
          id="graph-content-container"
          className="d-flex flex-column flex-grow-1 flex-shrink-1 border-start border-bottom border-end"
        >
          <GraphToolbar
            onSavePlotClick={handleSavePlotClick}
            onGenerateReportPdfClick={handleGenerateReportPdfClick}
            onSaveReportTemplateClick={handleSaveReportTemplate}
            isReportGenerating={isReportGenerating}
            handlePlotUnitsChange={handlePlotUnitsChange}
            handlePlotStyleChange={handlePlotStyleChange}
          />
          {plotStyle === SCATTER_PLOT_STYLE ? (
            <GraphPlot
              report={report}
              plotUnits={plotUnits}
              onPlotUpdate={handlePlotUpdate}
            />
          ) : (
            <GraphBarChart
              report={report}
              plotUnits={plotUnits}
              onPlotUpdate={handlePlotUpdate}
            />
          )}
          {/* <DemoProgressMonitorGraph /> */}
        </div>
        {modal}
      </>
    );
  }
}
