import { useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  convertValueToZ,
  convertZToValue,
  getNumberFormatByType,
} from "../../../helpers/statsHelperFunctions";

import {
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  Button,
  FormControl,
  FormSelect,
} from "react-bootstrap";

import ScoreDescriptionListItemMarkerStyleSelector from "./ScoreDescriptionListItemMarkerStyleSelector";
import ScoreDescriptionSubScore from "./ScoreDescriptionSubScore";

const colorPaletteList = [
  "#6929c4",
  "#1192e8",
  "#005d5d",
  "#9f1853",
  "#fa4d56",
  "#570408",
  "#198038",
  "#002d9c",
  "#ee538b",
  "#b28600",
  "#009d9a",
  "#012749",
  "#8a3800",
  "#a56eff",
];

export default function ScoreDescriptionListItem({
  scoreDescription,
  scoreDescriptionChangeHandler,
  deleteButtonClickHandler,
  addNewSubScoreHandler,
  subScores,
}) {
  const [scoreName, setScoreName] = useState(
    scoreDescription.name === "Score" ? "" : scoreDescription.name
  );

  const handleNameChange = (value) => {
    setScoreName(value);
    scoreDescriptionChangeHandler({
      ...scoreDescription,
      name: value === "" ? "Score" : value,
    });
  };

  const handleScoreChange = (value) => {
    scoreDescriptionChangeHandler({
      ...scoreDescription,
      z: convertValueToZ(value, scoreDescription.type),
      score: value,
    });
  };

  const handleDeleteButtonClick = () => {
    deleteButtonClickHandler(scoreDescription.id);
  };

  const handleTypeSelectChange = (type) => {
    const score = convertZToValue(scoreDescription.z, type);
    scoreDescriptionChangeHandler({
      ...scoreDescription,
      score,
      type,
    });
  };

  const handleColorChange = (markerColor) => {
    scoreDescriptionChangeHandler({
      ...scoreDescription,
      markerColor,
    });
  };

  const handleMarkerChange = (markerStyle) => {
    scoreDescriptionChangeHandler({
      ...scoreDescription,
      markerStyle,
    });
  };

  const handleVisibleChange = (e) => {
    e.stopPropagation();
    scoreDescriptionChangeHandler({
      ...scoreDescription,
      visible: !scoreDescription.visible,
    });
  };

  return (
    <AccordionItem
      key={scoreDescription.id}
      eventKey={scoreDescription.id}
      className="border-bottom border-start"
    >
      <AccordionHeader>
        <div className="d-flex align-items-center">
          <div className="w-100 px-1">
            <FormControl
              type="text"
              size="sm"
              placeholder="Score Name"
              value={scoreName}
              onChange={(e) => handleNameChange(e.target.value)}
              onFocus={(e) => e.target.select()}
              onClick={(e) => e.stopPropagation()}
              onKeyUp={(e) => {
                e.preventDefault();
              }}
            />
          </div>
          <div className="flex-shrink-1 px-1">
            <FormControl
              type="number"
              size="sm"
              step={
                getNumberFormatByType(
                  convertZToValue(scoreDescription.z, scoreDescription.type),
                  scoreDescription.type
                ).step
              }
              value={
                getNumberFormatByType(
                  convertZToValue(scoreDescription.z, scoreDescription.type),
                  scoreDescription.type
                ).value
              }
              onChange={(e) => handleScoreChange(e.target.value)}
              onFocus={(e) => e.target.select()}
              onClick={(e) => e.stopPropagation()}
            />
          </div>
          <div>
            <Button
              variant="light"
              onClick={handleVisibleChange}
              style={{ width: "46px" }}
            >
              <FontAwesomeIcon
                icon={`fa-regular ${
                  scoreDescription.visible ? "fa-eye" : "fa-eye-slash"
                }`}
              />
            </Button>
          </div>
          <div className="ms-5 pe-1">
            <Button
              size="sm"
              variant="danger"
              onClick={(e) => {
                handleDeleteButtonClick();
                e.stopPropagation();
              }}
            >
              <FontAwesomeIcon icon="fa-regular fa-trash-can" />
            </Button>
          </div>
        </div>
      </AccordionHeader>
      <AccordionBody>
        <div className="d-flex flex-column">
          <div className="d-flex align-items-center">
            <div className="m-1">
              <FormSelect
                size="sm"
                onChange={(e) => handleTypeSelectChange(e.target.value)}
                value={scoreDescription.type}
              >
                <option value="Standard">Standard</option>
                <option value="Scaled">Scaled</option>
                <option value="Percentile">Percentile</option>
                <option value="t">t-Score</option>
              </FormSelect>
            </div>
            <div className="m-1">
              <FormControl
                type="color"
                size="sm"
                list="colors"
                value={scoreDescription.markerColor}
                onChange={(e) => handleColorChange(e.target.value)}
                className="score-description-color-selector-option"
              />
              <datalist id="colors">
                {colorPaletteList.map((color) => (
                  <option>{color}</option>
                ))}
              </datalist>
            </div>
            <div className="m-1 border rounded">
              <ScoreDescriptionListItemMarkerStyleSelector
                value={scoreDescription.markerStyle}
                color={scoreDescription.markerColor}
                onValueChange={handleMarkerChange}
              />
            </div>
          </div>
          <Button onClick={() => addNewSubScoreHandler(scoreDescription)}>
            + Subtest Score
          </Button>
          <div className="d-flex flex-column py-1">
            {subScores.map((subScore) => (
              <ScoreDescriptionSubScore
                subScoreDescription={subScore}
                scoreDescriptionChangeHandler={scoreDescriptionChangeHandler}
                deleteButtonClickHandler={deleteButtonClickHandler}
              />
            ))}
          </div>
        </div>
      </AccordionBody>
    </AccordionItem>
  );
}
