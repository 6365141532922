import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { useAuth } from "../../hooks/useAuth";

import {
  Accordion,
  Button,
  FormControl,
  FormFloating,
  FormLabel,
} from "react-bootstrap";

import {
  createScore,
  createSubScore,
  deleteScore,
  getReport,
  updateReport,
  updateScores,
} from "../../api/report";

import ScoreDescriptionListItem from "./ScoreDescriptionList/ScoreDescriptionListItem";
import LoadingSpinner from "../LoadingSpinner";
import Graph from "./Graph/Graph";

export default function CurrentReport({ reportId }) {
  const location = useLocation();
  const { auth } = useAuth();
  const [loading, setLoading] = useState(false);
  const [report, setReport] = useState();
  const [reportName, setReportName] = useState("");
  const nextScoreIndex = useRef(0);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchReport = async () => {
      const reportId = location.state?.reportId || null;
      if (reportId) {
        setLoading(true);
        const fetchedReport = await getReport(reportId, auth);
        setReport(fetchedReport);
        setReportName(
          fetchedReport.name === "New Report" ? "" : fetchedReport.name
        );
        setLoading(false);
      } else {
        navigate("/dashboard/open-report");
      }
    };

    fetchReport();
  }, []);

  const handleSetReportName = async (name) => {
    setReportName(name);
    const newReport = { ...report, name: name === "" ? "New Report" : name };
    const previousReport = report;
    setReport(newReport);
    await updateReport(newReport, auth, () => {
      setReport(previousReport);
    });
  };

  const handleUpdateScore = async (updatedScore) => {
    const updatedReportScores = report.scores.map((score) => {
      if (score.id === updatedScore.id) {
        return updatedScore;
      } else if (score.parentScoreId === updatedScore.id) {
        return {
          ...score,
          markerColor: updatedScore.markerColor,
          markerStyle:
            updatedScore.markerStyle > 100
              ? updatedScore.markerStyle - 100
              : updatedScore.markerStyle + 100,
          visible: updatedScore.visible,
        };
      } else {
        return score;
      }
    });
    const updatedScores = updatedReportScores.filter(
      (score) =>
        score.parentScoreId === updatedScore.id || score.id === updatedScore.id
    );

    const previousReport = report;
    setReport({ ...report, scores: updatedReportScores });
    const idList = updatedScores.map((score) => score.id);
    await updateScores(idList, updatedScores, auth);
  };

  const getNewScoreIndex = () => {
    const newScoreIndex = nextScoreIndex.current;
    nextScoreIndex.current += 1;
    return newScoreIndex;
  };

  const addNewScore = async () => {
    const newScore = await createScore(report, getNewScoreIndex(), auth);
    setReport({ ...report, scores: [...report.scores, newScore] });
  };

  const addNewSubScore = async (parentScore) => {
    const newSubScore = await createSubScore(
      parentScore,
      getNewScoreIndex(),
      auth
    );
    setReport({ ...report, scores: [...report.scores, newSubScore] });
  };

  const handleDeleteScore = async (deletedScoreDescriptionId) => {
    await deleteScore(report, deletedScoreDescriptionId, auth);
    let updatedScores = report.scores.filter(
      (score) =>
        score.id !== deletedScoreDescriptionId &&
        score.parentScoreId !== deletedScoreDescriptionId
    );
    setReport({ ...report, scores: updatedScores });
  };

  const getParentScores = () => {
    return report?.scores?.filter((score) => !score.isSubScore);
  };

  const getSubScoresForScore = (parentScore) => {
    return report?.scores?.filter(
      (score) => score.parentScoreId === parentScore.id
    );
  };

  return (
    <>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <div id="current-report-container" className="d-flex w-100">
          <div
            id="report-edit-container"
            className="d-flex flex-column border-bottom flex-grow-0 flex-shrink-0"
            style={{ width: "30rem" }}
          >
            <div
              id="report-name-container"
              className="p-1 border-start border-bottom"
            >
              <FormFloating>
                <FormControl
                  type="text"
                  id="reportName"
                  placeholder="Report Name"
                  autoFocus
                  value={reportName}
                  onChange={(e) => handleSetReportName(e.target.value)}
                  onFocus={(e) => e.target.select()}
                />
                <FormLabel htmlFor="reportName">Report Name</FormLabel>
              </FormFloating>
            </div>
            <div className="flex-grow-1" style={{ height: "0px" }}>
              <div
                id="score-description-list-container"
                className="overflow-auto h-100"
              >
                <Accordion flush>
                  {getParentScores()?.map((score) => (
                    <ScoreDescriptionListItem
                      key={score.id}
                      scoreDescription={score}
                      subScores={getSubScoresForScore(score)}
                      scoreDescriptionChangeHandler={handleUpdateScore}
                      deleteButtonClickHandler={handleDeleteScore}
                      addNewSubScoreHandler={addNewSubScore}
                    />
                  ))}
                </Accordion>
              </div>
            </div>
            <Button onClick={addNewScore} className="mt-auto rounded-0">
              +
            </Button>
          </div>
          <Graph report={report} />
        </div>
      )}
    </>
  );
}
