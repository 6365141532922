import React from "react";

import { Link } from "react-router-dom";

import LogoAsset from "../../../assets/LogoAsset";
import { useAuth } from "../../../hooks/useAuth";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function TopNav() {
  const { auth } = useAuth();

  return (
    // <TopNavContainer>
    <div
      className="d-flex justify-content-between align-items-center px-5 border-bottom bg-primary bg-gradient sticky-top"
      style={{ minHeight: "5rem" }}
    >
      <div>
        <Link to="/">
          <LogoAsset backgroundColor={"rgba(0,0,0,0"} logoColor={"#fff"} />
        </Link>
      </div>
      <div>
        {auth.user ? (
          <div className="d-flex">
            <Link to="/dashboard/open-report" className="text-white">
              <div className="px-1">Dashboard</div>
            </Link>
            |
            <Link to="/account" className="text-white d-flex">
              <div className="px-1">Account</div>
              <div className="pe-1">
                <FontAwesomeIcon icon="fa-regular fa-user" />
              </div>
            </Link>
          </div>
        ) : (
          <>
            <span>
              <Link to="/register" className="text-white">
                Register
              </Link>{" "}
              |{" "}
              <Link to="/login" className="text-white">
                Login
              </Link>
            </span>
          </>
        )}
      </div>
    </div>
  );
}
