import {
  Button,
  Col,
  Container,
  FormControl,
  FormFloating,
  FormLabel,
  Row,
} from "react-bootstrap";
import { useAuth } from "../../hooks/useAuth";
import useStayLoggedIn from "../../hooks/useStayLoggedIn";
import wiscImage from "../../assets/images/wisc-hero-rotated.png";
import { useRef, useState } from "react";
import { handleSubmitMoreInfoEmail } from "../../api/moreInfo";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function Home() {
  const [email, setEmail] = useState("");
  const [isEmailValidated, setIsEmailValidated] = useState(true);
  const nameInputRef = useRef();

  const { auth } = useAuth();
  useStayLoggedIn(auth);

  const handleEmailInput = (e) => {
    setIsEmailValidated(true);
    setEmail(e.target.value);
  };

  const validateEmail = () => {
    const regex = new RegExp("^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,4}$");
    return regex.test(email);
  };

  const submitMoreInfoRequest = () => {
    if (validateEmail()) {
      if (nameInputRef.current.value === "") {
        console.log(document.referrer);
        handleSubmitMoreInfoEmail(email, document.referrer);
      }
    } else {
      setIsEmailValidated(false);
    }
  };

  return (
    <Container fluid className="flex-grow-1 bg-primary">
      <Row className="align-items-center border-bottom mb-4">
        <Col className="col-5 mb-4">
          <h1 className="text-white">
            Understanding standardized test results can be hard. Make it easy
            with EasyViz.
          </h1>
          <h5 className="mt-5 text-white">
            EasyViz gives providers the best way to display, record, and monitor
            the progress of <u>any</u> standardized test.
          </h5>
          <div className="mt-5">
            <FormFloating>
              <FormControl
                id="email"
                placeholder="Email"
                type="email"
                className="mb-2"
                isInvalid={!isEmailValidated}
                value={email}
                onChange={handleEmailInput}
              />
              <FormLabel htmlFor="email">Email</FormLabel>
            </FormFloating>
            <FormControl
              id="name"
              hidden={true}
              readOnly={true}
              ref={nameInputRef}
              value=""
            />
            <Button
              size="lg"
              variant="secondary"
              onClick={submitMoreInfoRequest}
            >
              Get Notified About Updates
            </Button>
          </div>
        </Col>
        <Col className="my-2">
          <img src={wiscImage} alt="WISC" className="img-fluid" />
        </Col>
      </Row>
      <Row className="mb-3 align-items-center">
        <Col className="col-4 text-white">
          <h5>
            With EasyViz, providers can quickly and easily input the results of
            any standarized test and present thsoe results in an intuitive and
            easy to undertand format. This allows those who need the information
            to understand it, quickly.
          </h5>
        </Col>
        <Col className="col-2">
          <FontAwesomeIcon
            icon="fa-regular fa-lightbulb"
            size="10x"
            color="#c8a2c8"
          />
        </Col>
      </Row>
      <Row className="mb-3 justify-content-end align-items-center">
        <Col className="col-2">
          <FontAwesomeIcon
            icon="fa-regular fa-clock"
            size="10x"
            color="#c8a2c8"
          />
        </Col>
        <Col className="col-4 text-white">
          <h5>
            EasyViz saves you time recoding the results of standardized tests.
            Upload test results in any format, including handwritten score data,
            and immediately view the results.
          </h5>
        </Col>
      </Row>
      <Row className="mb-3 align-items-center">
        <Col className="col-4 text-white">
          <h5>
            With our progress monitoring features, tests results can be compared
            to see test takers' change over time.
          </h5>
        </Col>
        <Col className="col-2">
          <FontAwesomeIcon
            icon="fa-solid fa-chart-line"
            size="10x"
            color="#c8a2c8"
          />
        </Col>
      </Row>
    </Container>
  );
}
