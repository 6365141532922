import { useState } from "react";

import { useAuth } from "../../hooks/useAuth";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Form,
  FormControl,
  FormFloating,
  FormLabel,
} from "react-bootstrap";
import LoadingSpinner from "../LoadingSpinner";
import useOkModal from "../../hooks/useOkModal";

export default function Register() {
  const [accessCode, setAccessCode] = useState("");
  const [userName, setUserName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [organization, setOrganization] = useState("");
  const [email, setEmail] = useState("");
  const [confirmEmail, setConfirmEmail] = useState("");
  const [password, setPassword] = useState("");
  const [verifyPassword, setVerifyPassword] = useState("");

  const [hasFormBeenValidated, setHasFormBeenValidated] = useState(false);

  const { auth } = useAuth();
  const [modal, showModal] = useOkModal();
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    if (!isFormDataValid()) {
      e.preventDefault();
      setHasFormBeenValidated(true);
      return;
    }

    e.preventDefault();

    try {
      const { status } = await auth.register({
        userName,
        email,
        password,
        firstName,
        lastName,
        organization,
        accessCode,
      });

      if (status === 201) {
        showModal("Succes", "Account created successfully!", navigateToLogin);
      } else {
        if (status === 409) {
          showModal("Error", "Account already exists with user name or email");
          if (status === 406) {
            showModal("Error", "The access code provided is invalid");
          }
        } else if (status === 500 || status === 400) {
          showModal("Error", "Server error");
        }
      }
    } catch (err) {
      showModal("Error", err);
    }
  };

  const navigateToLogin = () => {
    navigate("/login");
  };

  const isUserNameValid = () => {
    const regex = new RegExp("^[A-Za-z0-9]{4,}$");
    return regex.test(userName);
  };

  const isEmailValid = () => {
    const regex = new RegExp("^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,4}$");
    return regex.test(email);
  };

  const doEmailsMatch = () => {
    return email !== "" && confirmEmail !== "" && email === confirmEmail;
  };

  const isPasswordValid = () => {
    const regex = new RegExp(
      "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$"
    );
    return regex.test(password);
  };

  const doPasswordsMatch = () => {
    return password === verifyPassword;
  };

  const isFormDataValid = () => {
    if (
      !isUserNameValid() ||
      !isEmailValid() ||
      !doEmailsMatch() ||
      !isPasswordValid() ||
      !doPasswordsMatch()
    ) {
      return false;
    }
    return true;
  };

  return (
    <>
      {auth.isLoading ? (
        <LoadingSpinner />
      ) : (
        <div className="d-flex h-100 w-100 justify-content-center align-items-center">
          <div
            className="d-flex flex-column flex-shrink-1 mt-4 mb-auto p-2 shadow rounded"
            style={{ width: "30rem" }}
          >
            <div className="mx-auto my-2 w-50">
              <h3 className="text-center">Register</h3>
            </div>
            <div className="p-2">
              <Form onSubmit={handleSubmit}>
                <FormFloating className="mb-2">
                  <FormControl
                    isValid={
                      hasFormBeenValidated && isUserNameValid() ? true : null
                    }
                    isInvalid={
                      hasFormBeenValidated && !isUserNameValid() ? true : null
                    }
                    value={userName}
                    autoFocus
                    type="text"
                    id="userName"
                    placeholder="User Name"
                    onChange={(e) => setUserName(e.target.value)}
                  />
                  <FormLabel htmlFor="userName">User Name</FormLabel>
                </FormFloating>
                <FormFloating className="mb-2">
                  <FormControl
                    isValid={
                      hasFormBeenValidated && isEmailValid() ? true : null
                    }
                    isInvalid={
                      hasFormBeenValidated && !isEmailValid() ? true : null
                    }
                    value={email}
                    type="text"
                    id="email"
                    placeholder="Email"
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <FormLabel htmlFor="email">Email</FormLabel>
                </FormFloating>
                <FormFloating className="mb-2">
                  <FormControl
                    isValid={
                      hasFormBeenValidated && doEmailsMatch() ? true : null
                    }
                    isInvalid={
                      hasFormBeenValidated && !doEmailsMatch() ? true : null
                    }
                    value={confirmEmail}
                    type="text"
                    id="confirmEmail"
                    placeholder="Confirm Email"
                    onChange={(e) => setConfirmEmail(e.target.value)}
                  />
                  <FormLabel htmlFor="confirmEmail">Confirm Email</FormLabel>
                </FormFloating>
                <FormFloating className="mb-2">
                  <FormControl
                    isValid={
                      hasFormBeenValidated && isPasswordValid() ? true : null
                    }
                    isInvalid={
                      hasFormBeenValidated && !isPasswordValid() ? true : null
                    }
                    value={password}
                    type="password"
                    id="password"
                    placeholder="Password"
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <FormLabel htmlFor="password">Password</FormLabel>
                </FormFloating>
                <FormFloating className="mb-2">
                  <FormControl
                    isValid={
                      hasFormBeenValidated && doPasswordsMatch() ? true : null
                    }
                    isInvalid={
                      hasFormBeenValidated && !doPasswordsMatch() ? true : null
                    }
                    value={verifyPassword}
                    type="password"
                    id="verify-password"
                    placeholder="Retype Password"
                    onChange={(e) => setVerifyPassword(e.target.value)}
                  />
                  <FormLabel htmlFor="verify-password">
                    Retype Password
                  </FormLabel>
                </FormFloating>
                <FormFloating className="mb-2">
                  <FormControl
                    isValid={
                      hasFormBeenValidated && firstName !== "" ? true : null
                    }
                    isInvalid={
                      hasFormBeenValidated && firstName === "" ? true : null
                    }
                    value={firstName}
                    type="text"
                    id="firstName"
                    placeholder="First Name"
                    onChange={(e) => setFirstName(e.target.value)}
                  />
                  <FormLabel htmlFor="firstName">First Name</FormLabel>
                </FormFloating>
                <FormFloating className="mb-2">
                  <FormControl
                    isValid={
                      hasFormBeenValidated && lastName !== "" ? true : null
                    }
                    isInvalid={
                      hasFormBeenValidated && lastName === "" ? true : null
                    }
                    value={lastName}
                    type="text"
                    id="lastName"
                    placeholder="Last Name"
                    onChange={(e) => setLastName(e.target.value)}
                  />
                  <FormLabel htmlFor="lastName">Last Name</FormLabel>
                </FormFloating>
                <FormFloating className="mb-2">
                  <FormControl
                    value={organization}
                    type="text"
                    id="organization"
                    placeholder="Organization"
                    onChange={(e) => setOrganization(e.target.value)}
                  />
                  <FormLabel htmlFor="organization">Organization</FormLabel>
                </FormFloating>
                <FormFloating className="mb-2">
                  <FormControl
                    value={accessCode}
                    type="text"
                    id="accessCode"
                    placeholder="Access Code"
                    onChange={(e) => setAccessCode(e.target.value)}
                  />
                  <FormLabel htmlFor="accessCode">Access Code</FormLabel>
                </FormFloating>
                <Button type="submit" className="btn btn-primary">
                  Register
                </Button>
              </Form>
            </div>
          </div>
        </div>
      )}
      {modal}
    </>
  );
}
