import React from "react";

export default function TriangleUpGraphMarker({ stroke, fill }) {
  return (
    <svg
      width="13"
      height="12"
      viewBox="0 0 13 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.2631 1.75L6.5 10L1.73686 1.75L11.2631 1.75Z"
        fill={fill}
        stroke={stroke}
        strokeWidth="1"
      />
    </svg>
  );
}
