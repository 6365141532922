export function postRequest(body) {
  return buildRequest("POST", body);
}

export function getRequest(body) {
  return buildRequest("GET", body);
}

export function putRequest(body) {
  return buildRequest("PUT", body);
}

export function deleteRequest(body) {
  return buildRequest("DELETE", body);
}

export function addCredentialsToRequest(req) {
  return { ...req, credentials: "include" };
}

function buildRequest(method, body) {
  return {
    method,
    headers: {
      "Content-Type": "application/json",
    },
    body: body ? JSON.stringify(body) : null,
  };
}
