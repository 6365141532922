import { useState } from "react";

import CircleGraphMarker from "../../../assets/GraphMarkers/CircleGraphMarker.js";
import SquareGraphMarker from "../../../assets/GraphMarkers/SquareGraphMarker.js";
import DiamondGraphMarker from "../../../assets/GraphMarkers/DiamondGraphMarker.js";
import CrossGraphMarker from "../../../assets/GraphMarkers/CrossGraphMarker.js";
import XGraphMarker from "../../../assets/GraphMarkers/XGraphMarker.js";
import TriangleUpGraphMarker from "../../../assets/GraphMarkers/TriangleUpGraphMarker.js";
import TriangleDownGraphMarker from "../../../assets/GraphMarkers/TriangleDownGraphMarker.js";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "react-bootstrap";

export default function ScoreDescriptionListItemMarkerStyleSelector({
  value,
  color,
  onValueChange,
}) {
  const [currentValue, setCurrentValue] = useState(value);

  const options = [
    {
      value: 0,
      label: <CircleGraphMarker stroke={"#000"} fill={color} />,
    },
    {
      value: 1,
      label: <SquareGraphMarker stroke={"#000"} fill={color} />,
    },
    {
      value: 2,
      label: <DiamondGraphMarker stroke={"#000"} fill={color} />,
    },
    {
      value: 3,
      label: <CrossGraphMarker stroke={"#000"} fill={color} />,
    },
    {
      value: 4,
      label: <XGraphMarker stroke={"#000"} fill={color} />,
    },
    {
      value: 5,
      label: <TriangleDownGraphMarker stroke={"#000"} fill={color} />,
    },
    {
      value: 6,
      label: <TriangleUpGraphMarker stroke={"#000"} fill={color} />,
    },
    {
      value: 100,
      label: <CircleGraphMarker stroke={color} fill={"rgba(0,0,0,0)"} />,
    },
    {
      value: 101,
      label: <SquareGraphMarker stroke={color} fill={"rgba(0,0,0,0)"} />,
    },
    {
      value: 102,
      label: <DiamondGraphMarker stroke={color} fill={"rgba(0,0,0,0)"} />,
    },
    {
      value: 103,
      label: <CrossGraphMarker stroke={color} fill={"rgba(0,0,0,0)"} />,
    },
    {
      value: 104,
      label: <XGraphMarker stroke={color} fill={"rgba(0,0,0,0)"} />,
    },
    {
      value: 105,
      label: <TriangleDownGraphMarker stroke={color} fill={"rgba(0,0,0,0)"} />,
    },
    {
      value: 106,
      label: <TriangleUpGraphMarker stroke={color} fill={"rgba(0,0,0,0)"} />,
    },
  ];
  const handleChange = (value) => {
    onValueChange(value);
    setCurrentValue(value);
  };

  return (
    <Dropdown>
      <DropdownToggle variant="light">
        {options.filter((option) => option.value === currentValue)[0].label}
      </DropdownToggle>
      <DropdownMenu>
        {options.map((option) => {
          return (
            <DropdownItem
              key={option.value}
              onClick={() => handleChange(option.value)}
            >
              {option.label}
            </DropdownItem>
          );
        })}
      </DropdownMenu>
    </Dropdown>
  );
}
