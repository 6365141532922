import { useState } from "react";
import { Button, FormControl } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function AccountPageItem({
  label,
  value,
  isEditable,
  editHandler,
}) {
  const [isEditing, setIsEditing] = useState(false);
  const [currentValue, setCurrentValue] = useState(value);

  const beginEditing = () => {
    setIsEditing(true);
  };

  const cancelEditing = () => {
    setIsEditing(false);
    setCurrentValue(value);
  };

  const saveEdits = () => {
    editHandler(currentValue);
    setIsEditing(false);
  };

  return (
    <div className="d-flex justify-content-between align-items-center mb-2">
      <div>
        <h5>{label}</h5>
      </div>
      <div
        className="d-flex mb-auto align-items-center justify-content-between"
        style={{ width: "15rem" }}
      >
        {isEditing ? (
          <>
            <FormControl
              className="mx-2 flex-shirnk-1"
              value={currentValue}
              autoFocus
              onFocus={(e) => e.target.select()}
              onChange={(e) => setCurrentValue(e.target.value)}
            />
            <Button className="mx-1" variant="light" onClick={saveEdits}>
              <FontAwesomeIcon icon="fa-regular fa-save" />
            </Button>
            <Button className="mx-1" variant="light" onClick={cancelEditing}>
              <FontAwesomeIcon icon="fa-solid fa-cancel" />
            </Button>
          </>
        ) : (
          <>
            <div className="mx-2">{currentValue}</div>
            {isEditable && (
              <Button variant="light" onClick={beginEditing}>
                <FontAwesomeIcon icon="fa-solid fa-pencil" />
              </Button>
            )}
          </>
        )}
      </div>
    </div>
  );
}
