import { useState } from "react";

export default function useError() {
  const [errors, setErrors] = useState([]);

  const addError = (err) => {
    console.log(err);
    setErrors([...errors, err]);
  };

  const clearErrors = () => {
    setErrors([]);
  };

  return {
    errors,
    addError,
    clearErrors,
  };
}
