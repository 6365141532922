import { Button, FormSelect } from "react-bootstrap";
import GraphToolbarButtonContainer from "./GraphToolbarButtonContainer";
import { BAR_PLOT_STYLE, SCATTER_PLOT_STYLE } from "./Graph";

export default function GraphToolbar({
  onSavePlotClick,
  onGenerateReportPdfClick,
  onSaveReportTemplateClick,
  isReportGenerating,
  handlePlotUnitsChange,
  handlePlotStyleChange,
}) {
  return (
    <div className="d-flex p-2 flex-shrink-1 border-bottom">
      <GraphToolbarButtonContainer>
        <Button size="sm" onClick={onSavePlotClick}>
          Save Chart Image
        </Button>
      </GraphToolbarButtonContainer>
      <GraphToolbarButtonContainer>
        <Button
          size="sm"
          onClick={onGenerateReportPdfClick}
          disabled={isReportGenerating}
        >
          Generate Report PDF
        </Button>
      </GraphToolbarButtonContainer>
      <GraphToolbarButtonContainer>
        <Button size="sm" onClick={onSaveReportTemplateClick}>
          Save Report Template
        </Button>
      </GraphToolbarButtonContainer>
      <GraphToolbarButtonContainer additionalClasses="text-align-center">
        Graph Units
      </GraphToolbarButtonContainer>
      <GraphToolbarButtonContainer>
        <FormSelect size="sm" onChange={handlePlotUnitsChange}>
          <option value="Standard">Standard Score</option>
          <option value="Scaled">Scaled Score</option>
          <option value="Percentile">Percentile</option>
          <option value="t">t-Score</option>
        </FormSelect>
      </GraphToolbarButtonContainer>
      <GraphToolbarButtonContainer>
        <FormSelect size="sm" onChange={handlePlotStyleChange}>
          <option value={SCATTER_PLOT_STYLE}>Normal Distribution</option>
          <option value={BAR_PLOT_STYLE}>Bar Chart</option>
        </FormSelect>
      </GraphToolbarButtonContainer>
    </div>
  );
}
