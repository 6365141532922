import { useEffect } from "react";
// import { useNavigate } from "react-router-dom";

export default function useStayLoggedIn(auth) {
  //   const navigate = useNavigate();

  useEffect(() => {
    const tryStayLoggedIn = async () => {
      if (!auth.user) {
        await auth.authenticateUserWithoutLogin();
      }
    };

    tryStayLoggedIn();
  }, []);
}
