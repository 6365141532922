import React from "react";

export default function DiamondGraphMarker({ stroke, fill }) {
  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="1.41421"
        y="8.48529"
        width="10"
        height="10"
        transform="rotate(-45 1.41421 8.48529)"
        fill={fill}
        stroke={stroke}
        strokeWidth="1"
      />
    </svg>
  );
}
