import {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest,
} from "../api/apiHelpers";

const REPORT_URL = `${process.env.REACT_APP_API_URL}/report`;
const SCORE_URL = `${process.env.REACT_APP_API_URL}/score`;
const SCORE_MULTIPLE_URL = `${process.env.REACT_APP_API_URL}/score/multiple`;

const DEFAULT_SCORE = {
  name: "Score",
  score: 100,
  type: "Standard",
  z: 0,
  markerColor: "#6929c4",
  markerStyle: 0,
};

export async function getReport(id, auth) {
  const res = await auth.fetchWithAuth(`${REPORT_URL}/${id}`, getRequest(null));

  if (!res.ok) {
    throw res;
  }

  const report = await res.json();
  return report;
}

export async function createReport({ name, patientId }, auth) {
  if (!name) {
    return;
  }

  const reportPatientId = patientId ? patientId : "";

  const res = await auth.fetchWithAuth(
    REPORT_URL,
    postRequest({ name, patientId: reportPatientId })
  );

  if (!res.ok) {
    throw res;
  }

  const newReport = await res.json();
  return newReport.id;
}

export async function updateReport(updatedReport, auth) {
  if (!updatedReport) {
    return;
  }

  const res = await auth.fetchWithAuth(REPORT_URL, putRequest(updatedReport));

  if (!res.ok) {
    throw res;
  }
}

export async function deleteReport(id, auth) {
  const res = await auth.fetchWithAuth(REPORT_URL, deleteRequest({ id }));

  if (!res.ok) {
    throw res;
  }
}

export async function createScore(report, scoreIndex, auth) {
  if (!report) {
    return;
  }

  const res = await auth.fetchWithAuth(
    SCORE_URL,
    postRequest({ ...DEFAULT_SCORE, id: report.id, scoreIndex })
  );

  if (!res.ok) {
    throw res;
  }

  const newScore = await res.json();
  return newScore;
}

export async function createSubScore(parentScore, scoreIndex, auth) {
  if (!parentScore) {
    return;
  }

  const res = await auth.fetchWithAuth(
    SCORE_URL,
    postRequest({
      name: "Subtest Score",
      score: parentScore.score,
      type: parentScore.type,
      z: parentScore.z,
      markerColor: parentScore.markerColor,
      markerStyle:
        parentScore.markerStyle >= 100
          ? parentScore.markerStyle - 100
          : parentScore.markerStyle + 100,
      parentScoreId: parentScore.id,
      id: parentScore.reportId,
      scoreIndex,
    })
  );

  if (!res.ok) {
    throw res;
  }

  const newScore = await res.json();
  return newScore;
}

export async function getScore(report, id) {
  return report.scores.find((score) => score.id === id);
}

export async function updateScore(report, updatedScore, auth) {
  if (!report || !updateScore) {
    return;
  }

  const previousScore = report.scores.find(
    (score) => score.id === updatedScore.id
  );
  if (!previousScore) {
    return;
  }

  const res = await auth.fetchWithAuth(SCORE_URL, putRequest(updatedScore));
  if (!res.ok) {
    throw new Error(res.message);
  }
}

export async function updateScores(idList, scoreList, auth) {
  if (
    !idList ||
    !scoreList ||
    idList.length === 0 ||
    scoreList.length === 0 ||
    idList.length !== scoreList.length
  ) {
    throw new Error("Invalid information supplied to update multiple scores");
  }

  const res = await auth.fetchWithAuth(
    SCORE_MULTIPLE_URL,
    putRequest({ idList, scoreList })
  );

  if (!res.ok) {
    const json = await res.json();
    throw new Error(json.message);
  }
}

export async function deleteScore(report, id, auth) {
  if (!report || !id) {
    return;
  }

  const deleteScore = report.scores.find((score) => score.id === id);
  if (!deleteScore) {
    return;
  }

  const res = await auth.fetchWithAuth(`${SCORE_URL}`, deleteRequest({ id }));

  if (!res.ok) {
    throw res;
  }
}
