import { useState } from "react";
import ReportTableHeaderCell from "./ReportTableHeaderCell";
import ReportTableFilterInput from "./ReportTableFilterInput";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  Table,
} from "react-bootstrap";
import ReportTableDataRow from "./ReportTableDataRow";

export const NONE_SORT_STATE = "none";
export const ASC_SORT_STATE = "asc";
export const DESC_SORT_STATE = "desc";

export default function ReportTable({
  reportList,
  deleteReport,
  openReport,
  isTemplateList,
}) {
  const [sortHeader, setSortHeader] = useState("Name");
  const [sortedReportList, setSortedReportList] = useState(reportList);
  const [checkedReportList, setCheckedReportList] = useState([]);
  const [isModalVisibible, setIsModalVisible] = useState(false);
  const [reportsStagedForDeletion, setReportsStagedForDeletion] = useState([]);

  const nameSortFunction = (sortState) => {
    if (sortState === NONE_SORT_STATE) {
      setSortedReportList(reportList);
      return;
    }

    const newSortedReportList = [...reportList].sort((a, b) => {
      const aName = a.name.toLowerCase();
      const bName = b.name.toLowerCase();
      if (aName < bName) {
        return sortState === ASC_SORT_STATE ? -1 : 1;
      }
      if (aName > bName) {
        return sortState === ASC_SORT_STATE ? 1 : -1;
      }
      return 0;
    });
    setSortedReportList(newSortedReportList);
  };

  const dateSortFunction = (sortState) => {
    if (sortState === NONE_SORT_STATE) {
      setSortedReportList(reportList);
      return;
    }
    const newSortedReportList = [...reportList].sort((a, b) => {
      const aDate = a.createdOn;
      const bDate = b.createdOn;
      if (aDate < bDate) {
        return sortState === ASC_SORT_STATE ? -1 : 1;
      }
      if (aDate > bDate) {
        return sortState === ASC_SORT_STATE ? 1 : -1;
      }
      return 0;
    });
    setSortedReportList(newSortedReportList);
  };

  const checkRow = (checked, reportId) => {
    const isReportOnList = checkedReportList.includes(reportId);
    if (checked && !isReportOnList) {
      setCheckedReportList([...checkedReportList, reportId]);
    } else if (!checked && isReportOnList) {
      const udpatedCheckedReportList = checkedReportList.filter(
        (report) => report.id !== reportId
      );
      setCheckedReportList(udpatedCheckedReportList);
    }
  };

  const handleSingleReportDeleteClick = (reportId) => {
    setReportsStagedForDeletion([
      reportList.filter((report) => report.id === reportId)[0],
    ]);
    setIsModalVisible(true);
  };

  const handleMultipleReportDeleteClick = () => {
    setReportsStagedForDeletion(checkedReportList.map((report) => report.id));
    setIsModalVisible(true);
  };

  return (
    <>
      {reportList.length === 0 ? (
        `No ${isTemplateList ? "Templates" : "Reports"}`
      ) : (
        <>
          <div className="mt-2">
            <div className="d-flex">
              <ReportTableFilterInput
                reportList={reportList}
                setFilteredReportList={setSortedReportList}
              />
              {/* <Button
                onClick={handleMultipleReportDeleteClick}
                size="sm"
                variant="danger"
              >
                Delete Checked Items
              </Button> */}
            </div>
            <Table striped bordered hover size="sm">
              <thead>
                <tr>
                  <th></th>
                  <ReportTableHeaderCell
                    active={sortHeader === "Name"}
                    name="Name"
                    setSortHeader={setSortHeader}
                    sortFunction={nameSortFunction}
                  />
                  <ReportTableHeaderCell
                    active={sortHeader === "Created On"}
                    name="Created On"
                    setSortHeader={setSortHeader}
                    sortFunction={dateSortFunction}
                  />
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {sortedReportList.map((report) => {
                  return (
                    <ReportTableDataRow
                      key={report.id}
                      report={report}
                      openReport={openReport}
                      deleteReport={handleSingleReportDeleteClick}
                      checkRow={checkRow}
                    />
                  );
                })}
              </tbody>
            </Table>
          </div>
          <Modal
            show={isModalVisibible}
            onHide={() => setIsModalVisible(false)}
            backdrop="static"
          >
            <ModalHeader closeButton>
              <ModalTitle>Confirm Delete Report</ModalTitle>
            </ModalHeader>
            <ModalBody>
              {reportsStagedForDeletion.length === 1
                ? `Are you sure you want to delete ${
                    isTemplateList ? "template" : "report"
                  } \"${reportsStagedForDeletion[0].name}\"?`
                : `Are you sure you want to delete ${reportsStagedForDeletion.length} reports?`}
            </ModalBody>
            <ModalFooter>
              <Button
                onClick={(e) => deleteReport(reportsStagedForDeletion[0].id)}
                variant="danger"
              >
                Yes
              </Button>
              <Button onClick={(e) => setIsModalVisible(false)}>No</Button>
            </ModalFooter>
          </Modal>
        </>
      )}
    </>
  );
}
