import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Home from "./components/Home/Home";
import Dashboard from "./components/Dashboard/Dashboard";
import Login from "./components/Login/Login";
import TopNav from "./components/Dashboard/TopNav/TopNav";
import Register from "./components/Register/Register";
import Account from "./components/Account/Account";

import RequireAuth from "./components/RequireAuth";

import "./scss/style.scss";
import "bootstrap/dist/js/bootstrap.bundle.min";

import fontAwesome from "./fontAwesome.js";

export default function App() {
  return (
    <div id="app-container" className="vh-100">
      <Router>
        <div
          id="app-content-container"
          className="d-flex flex-column w-100 h-100"
        >
          <TopNav />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route
              path="/account"
              element={
                <RequireAuth>
                  <Account />
                </RequireAuth>
              }
            />
            <Route
              path="/dashboard/*"
              element={
                <RequireAuth>
                  <Dashboard />
                </RequireAuth>
              }
            />
          </Routes>
        </div>
      </Router>
    </div>
  );
}
