import { FormControl, InputGroup } from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import InputGroupText from "react-bootstrap/esm/InputGroupText";

export default function ReportTableFilterInput({
  reportList,
  setFilteredReportList,
}) {
  const filterReportList = (e) => {
    const searchString = e.target.value.toLowerCase();
    console.log(searchString);
    if (searchString !== "") {
      const filteredReportList = reportList.filter((report) =>
        report.name.toLowerCase().includes(searchString)
      );
      console.log(reportList);
      console.log(filteredReportList);
      setFilteredReportList(filteredReportList);
    } else {
      setFilteredReportList(reportList);
    }
  };

  return (
    <InputGroup className="mb-4">
      <InputGroupText className="p-2">
        <FontAwesomeIcon icon="fa-solid fa-magnifying-glass" />
      </InputGroupText>
      <FormControl
        className="w-75"
        onFocus={(e) => e.target.select()}
        onChange={filterReportList}
        placeholder="Search..."
      />
    </InputGroup>
  );
}
