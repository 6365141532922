import { useState } from "react";
import { useAuth } from "../../hooks/useAuth";
import useOkModal from "../../hooks/useOkModal";
import { useNavigate } from "react-router-dom";
import { createPatient } from "../../api/patient";
import { Button } from "react-bootstrap";

export default function CreateNewPatient() {
  const [firstName, setFirstName] = useState("Eric");
  const [middleName, setMiddleName] = useState("Miguel");
  const [lastName, setLastName] = useState("Tridas");
  const [dob, setDob] = useState(new Date());
  const [sex, setSex] = useState("M");
  const [school, setSchool] = useState("St. Raphael's");
  const [grade, setGrade] = useState("8");
  const [providerPatientId, setProviderPatientId] = useState("");

  const [hasFormBeenValidated, setHasFormBeenValidated] = useState(true);

  const { auth } = useAuth();
  const [modal, showModal] = useOkModal();
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!isFormDataValid()) {
      setHasFormBeenValidated(true);
      return;
    }

    console.log("here");

    try {
      const { status, newPatientId } = await createPatient(
        {
          firstName,
          middleName,
          lastName,
          dob,
          sex,
          school,
          grade,
          providerPatientId,
        },
        auth
      );
      console.log(newPatientId);
    } catch (err) {}
  };

  const isFormDataValid = () => {
    return true;
  };

  return <Button onClick={handleSubmit}>Create New Patient</Button>;
}
