import { useState } from "react";
import {
  ASC_SORT_STATE,
  DESC_SORT_STATE,
  NONE_SORT_STATE,
} from "./ReportTable";

function sortStateSymbol(sortState) {
  switch (sortState) {
    case ASC_SORT_STATE:
      return "▲";
    case DESC_SORT_STATE:
      return "▼";
    default:
      return "";
  }
}

function getNextSortState(sortState) {
  switch (sortState) {
    case ASC_SORT_STATE:
      return DESC_SORT_STATE;
    case DESC_SORT_STATE:
      return NONE_SORT_STATE;
    case NONE_SORT_STATE:
      return ASC_SORT_STATE;
    default:
      return "";
  }
}

export default function ReportTableHeaderCell({
  name,
  active,
  setSortHeader,
  sortFunction,
}) {
  const [sortState, setSortState] = useState(NONE_SORT_STATE);

  if (!active && sortState !== NONE_SORT_STATE) {
    setSortState(NONE_SORT_STATE);
  }

  const handleSortButtonPress = (e) => {
    setSortState(getNextSortState(sortState));
    setSortHeader(name);
    sortFunction(getNextSortState(sortState));
  };

  return (
    <th>
      {name ? (
        <div
          className="d-flex justify-content-center user-select-none"
          role="button"
          onClick={handleSortButtonPress}
        >
          <span>{name}</span>
          <span>{`${sortStateSymbol(sortState)}`}</span>
        </div>
      ) : (
        ""
      )}
    </th>
  );
}
