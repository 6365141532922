import { useEffect, useState } from "react";
import { useAuth } from "../../hooks/useAuth";
import { getPatientList } from "../../api/patient";

export default function PatientList() {
  const { auth } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [patientList, setPatientList] = useState([]);

  useEffect(() => {
    const fetchPatientList = async () => {
      setIsLoading(true);
      const fetchedPatientList = await getPatientList(auth);
      setPatientList(fetchedPatientList);
      setIsLoading(false);
    };

    fetchPatientList();
  }, []);

  return (
    <div className="d-flex flex-column h-100">
      <div>
        <h1>Patients</h1>
      </div>
      <div>{patientList}</div>
    </div>
  );
}
