import React from "react";

export default function TriangleDownGraphMarker({ stroke, fill }) {
  return (
    <svg
      width="13"
      height="12"
      viewBox="0 0 13 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.73686 10.25L6.5 2L11.2631 10.25H1.73686Z"
        fill={fill}
        stroke={stroke}
        strokeWidth="1"
      />
    </svg>
  );
}
