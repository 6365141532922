import { useState, useContext, createContext, useRef } from "react";

import handleLogin from "../api/login";
import handleLogout from "../api/logout";
import handleRegister from "../api/register";
import handleRefreshToken from "../api/refreshToken";
import { getUser } from "../api/user";

export const authContext = createContext();

export const useAuth = () => {
  return useContext(authContext);
};

export const useProvideAuth = () => {
  const [user, setUser] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const retryingFetchAfterRefresh = useRef(false);
  const jwtAccessToken = useRef(false);

  const setJwtAccessToken = (accessToken) => {
    jwtAccessToken.current = accessToken;
  };

  const login = async ({ userName, password }) => {
    setIsLoading(true);
    const res = await handleLogin({ userName, password });
    const json = await res.json();
    if (res.ok) {
      setUser(json.user);
      setJwtAccessToken(json.jwtAccessToken);
    }
    setIsLoading(false);
    return { status: res.status, error: json.error, message: json.message };
  };

  const logout = async () => {
    setIsLoading(true);
    try {
      const res = await handleLogout();
      if (!res.ok) {
        console.log("error logging out from server");
      }
    } catch {
      // Todo?
    } finally {
      setUser(null);
      setJwtAccessToken("");
      setIsLoading(false);
    }
  };

  const register = async (registerData) => {
    setIsLoading(true);
    const res = await handleRegister(registerData);
    const json = res.json();
    setIsLoading(false);
    return { status: res.status, error: json.error, message: json.message };
  };

  const refreshToken = async () => {
    setIsLoading(true);
    const res = await handleRefreshToken();
    if (res.ok) {
      const { newJwtAccessToken } = await res.json();
      setJwtAccessToken(newJwtAccessToken);
      setIsLoading(false);
    } else {
      // errors.addError("Error refreshing authentication token");
      setIsLoading(false);
      throw res;
    }
  };

  const fetchWithAuth = async (url, req) => {
    setIsLoading(true);

    if (!user) {
      try {
        authenticateUserWithoutLogin();
      } catch {
        throw new Error("");
      }
    }

    req.headers = {
      ...req.headers,
      Authorization: `Bearer ${jwtAccessToken.current}`,
    };

    const res = await fetch(url, req);
    if (res.status === 403) {
      const { error } = await res.json();
      // 403 + !error is an expired JWT token
      if (!error && !retryingFetchAfterRefresh.current) {
        retryingFetchAfterRefresh.current = true;
        await refreshToken();
        return await fetchWithAuth(url, req);
      } else {
        throw res;
      }
    }

    if (retryingFetchAfterRefresh) {
      retryingFetchAfterRefresh.current = false;
    }

    setIsLoading(false);
    return res;
  };

  const authenticateUserWithoutLogin = async () => {
    try {
      await refreshToken();
      const foundUser = await getUser(jwtAccessToken.current);
      if (foundUser) {
        setUser(foundUser);
      }
    } catch (res) {
      if (res.status === 401) {
        //todo
      }
    }
  };

  // const getErrors = () => {
  //   return errors.errors;
  // };

  return {
    isLoading,
    // errors,
    user,
    login,
    logout,
    register,
    refreshToken,
    fetchWithAuth,
    authenticateUserWithoutLogin,
  };
};
