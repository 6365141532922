import { getRequest, putRequest } from "./apiHelpers";

const USER_URL = `${process.env.REACT_APP_API_URL}/user`;

export async function getUser(jwtAccessToken, id) {
  let req = getRequest(null);
  req.headers = { ...req.headers, Authorization: `Bearer ${jwtAccessToken}` };
  let res;
  if (id === undefined) {
    res = await fetch(USER_URL, req);
  } else {
    res = await fetch(`${USER_URL}/${id}`, req);
  }

  if (!res.ok) {
    throw res;
  }

  const { user } = await res.json();
  return user;
}

export async function updateUser(user, auth) {
  if (!user) {
    return;
  }

  const res = await auth.fetchWithAuth(USER_URL, putRequest({ user }));

  if (!res.ok) {
    const { message } = await res.json();
    throw new Error(message);
  }
}
