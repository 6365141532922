import React from "react";

export default function XGraphMarker({ stroke, fill }) {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.82843 6.07108L4.65685 8.89951L1.82843 11.7279L6.07107 15.9706L8.89949 13.1421L11.7279 15.9706L15.9706 11.7279L13.1421 8.89951L15.9706 6.07108L11.7279 1.82844L8.89949 4.65687L6.07107 1.82844L1.82843 6.07108Z"
        fill={fill}
        stroke={stroke}
        strokeWidth="1"
      />
    </svg>
  );
}
