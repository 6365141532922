import React from "react";

export default function OpenReportNavIcon({
  backgroundColor = "rgba(0,0,0,0)",
  iconColor = "#213231",
}) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill={backgroundColor}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4 20C3.45 20 2.979 19.804 2.587 19.412C2.195 19.02 1.99934 18.5493 2 18V6C2 5.45 2.196 4.979 2.588 4.587C2.98 4.195 3.45067 3.99934 4 4H9.175C9.44167 4 9.696 4.05 9.938 4.15C10.18 4.25 10.3923 4.39167 10.575 4.575L12 6H20C20.55 6 21.021 6.196 21.413 6.588C21.805 6.98 22.0007 7.45067 22 8H11.175L9.175 6H4V18L5.975 11.425C6.10833 10.9917 6.35434 10.6457 6.713 10.387C7.07167 10.1283 7.46734 9.99934 7.9 10H20.8C21.4833 10 22.021 10.271 22.413 10.813C22.805 11.355 22.909 11.9423 22.725 12.575L20.925 18.575C20.7917 19.0083 20.5457 19.3543 20.187 19.613C19.8283 19.8717 19.4327 20.0007 19 20H4ZM6.1 18H19L20.8 12H7.9L6.1 18Z"
        fill={iconColor}
      />
    </svg>
  );
}
