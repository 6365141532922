import { deleteRequest, postRequest } from "./apiHelpers";

const TEMPLATE_URL = `${process.env.REACT_APP_API_URL}/report/template`;
const TEMPLATE_CREATE_REPORT_URL = `${process.env.REACT_APP_API_URL}/report/template/create`;

export async function createTemplate(id, name, auth) {
  if (!id || !name) {
    return;
  }

  const res = await auth.fetchWithAuth(TEMPLATE_URL, postRequest({ id, name }));
  if (!res.ok) {
    throw res;
  }
}

export async function deleteTemplate(id, auth) {
  if (!id) {
    return;
  }

  const res = await auth.fetchWithAuth(TEMPLATE_URL, deleteRequest({ id }));
  if (!res.ok) {
    throw res;
  }
}

export async function createReportFromTemplate(id, auth) {
  if (!id) {
    return;
  }

  const res = await auth.fetchWithAuth(
    TEMPLATE_CREATE_REPORT_URL,
    postRequest({ id, createById: auth.user.id })
  );
  if (!res.ok) {
    throw res;
  }
  const { newReportId } = await res.json();
  return newReportId;
}
