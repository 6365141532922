import { useNavigate } from "react-router-dom";
import { createReport } from "../../api/report";
import { useAuth } from "../../hooks/useAuth";
import useReportList from "../../hooks/useReportList";
import { createReportFromTemplate, deleteTemplate } from "../../api/template";
import ReportTable from "../Dashboard/ReportTable/ReportTable";

import { Button, Container } from "react-bootstrap";
import LoadingSpinner from "../LoadingSpinner";

export default function NewReport({ setDashboardReportId, setActiveNavKey }) {
  const navigate = useNavigate();
  const { auth } = useAuth();
  const { isLoading, errors, reportList, reloadList } = useReportList(true);

  const createNewReport = async () => {
    try {
      const reportId = await createReport(
        {
          name: "New Report",
          patientId: "348be0cd-a700-4903-a334-8039cfcef828",
        },
        auth
      );
      setDashboardReportId(reportId);
      setActiveNavKey("current-report");
      navigate("/dashboard/current-report", {
        state: { reportId },
      });
    } catch (res) {
      console.log("Error creating report");
      console.log(res);
    }
  };

  const handleCreateReportFromTemplate = async (id) => {
    try {
      const reportId = await createReportFromTemplate(id, auth);
      setDashboardReportId(reportId);
      navigate("/dashboard/current-report", {
        state: { reportId },
      });
    } catch (res) {
      console.log("Error creating report from template");
      console.log(res);
    }
  };

  const handleDeleteTemplate = async (id) => {
    try {
      await deleteTemplate(id, auth);
      reloadList();
    } catch (res) {
      //TODO
    }
  };

  return (
    <div className="d-flex flex-column p-4" style={{ width: "60rem" }}>
      <div className="pb-4">
        <Button variant="secondary" className="w-25" onClick={createNewReport}>
          + Create New Blank Report
        </Button>
      </div>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <Container className="border">
          <h2>Templates</h2>
          <ReportTable
            reportList={reportList}
            openReport={handleCreateReportFromTemplate}
            deleteReport={handleDeleteTemplate}
            isTemplateList={true}
          />
        </Container>
      )}
    </div>
  );
}
