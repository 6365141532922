import React from "react";

export default function CircleGraphMarker({ stroke, fill }) {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="7.5"
        cy="7.5"
        r="6.5"
        fill={fill}
        stroke={stroke}
        strokeWidth="1"
      />
    </svg>
  );
}
