import jstat from "jstat";

export function convertValueToZ(value, type) {
  // TODO: validation
  switch (type) {
    case "Percentile":
      return jstat.normal.inv(value / 100, 0, 1);
    case "Standard":
      return (value - 100) / 15;
    case "Scaled":
      return (value - 10) / 3;
    case "Z-Score":
      return value;
    case "t":
      return (value - 50) / 10;
    default:
      return 0;
  }
}

export function convertZToValue(value, type) {
  // TODO: validation
  switch (type) {
    case "Percentile":
      return jstat.normal.cdf(value, 0, 1) * 100;
    case "Standard":
      return value * 15 + 100;
    case "Scaled":
      return value * 3 + 10;
    case "Z-Score":
      return value;
    case "t":
      return value * 10 + 50;
    default:
      return 0;
  }
}

export function getNumberFormatByType(value, type) {
  let fixedPoint = 0;
  switch (type) {
    case "Percentile":
      fixedPoint = 0;
      break;
    case "Standard":
      fixedPoint = 0;
      break;
    case "Scaled":
      fixedPoint = 0;
      break;
    case "Z-Score":
      fixedPoint = 1;
      break;
    case "t":
      fixedPoint = 0;
      break;
    default:
      fixedPoint = 0;
      break;
  }

  return {
    value: Number(value).toFixed(fixedPoint),
    step: Math.pow(10, -fixedPoint),
  };
}

export function getMeanFromType(type) {
  switch (type) {
    case "Percentile":
      return 50;
    case "Standard":
      return 100;
    case "Scaled":
      return 10;
    case "Z-Score":
      return 0;
    case "t":
      return 50;
    default:
      return 0;
  }
}

export function getStdDevFromType(type) {
  switch (type) {
    case "Percentile":
      return 34.13447399 / 2;
    case "Standard":
      return 15;
    case "Scaled":
      return 3;
    case "Z-Score":
      return 0;
    case "t":
      return 10;
    default:
      return 0;
  }
}

export function getTypeTextFromType(type) {
  switch (type) {
    case "Percentile":
      return type;
    case "Standard":
      return "Standard Score";
    case "Scaled":
      return "Scaled Sore";
    case "Z-Score":
      return type;
    case "t":
      return "t-Score";
    default:
      return "";
  }
}

const numPointsInNormalDistributionPlot = 1001;
const normalDistributionStart = -4;
const normalDistributionStop = 4;

export function getNormalDistributionPlotData(units) {
  let data = { x: [], y: [] };
  for (
    let x = normalDistributionStart;
    x <= normalDistributionStop;
    x =
      x +
      (normalDistributionStop - normalDistributionStart) /
        numPointsInNormalDistributionPlot
  ) {
    data.x = [...data.x, convertZToValue(x, units)];
    data.y = [
      ...data.y,
      jstat.normal.pdf(
        convertZToValue(x, units),
        getMeanFromType(units),
        getStdDevFromType(units)
      ),
    ];
  }

  return data;
}
