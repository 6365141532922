import { useEffect, useState } from "react";
import { getRequest } from "../api/apiHelpers";
import { useAuth } from "./useAuth";
import useError from "./useError";

const REPORT_URL = `${process.env.REACT_APP_API_URL}/report`;

export default function useReportList(templates) {
  const [isLoading, setIsLoading] = useState(false);
  const [reportList, setReportList] = useState([]);
  const [listChanged, setListChanged] = useState(false);
  const errors = useError();

  const { auth } = useAuth();

  useEffect(() => {
    const getReportList = async () => {
      setIsLoading(true);
      const res = await auth.fetchWithAuth(
        `${REPORT_URL}${templates ? "/template" : ""}`,
        getRequest(null)
      );

      console.log(res);
      if (res.ok) {
        const fetchedReportList = await res.json();
        setReportList(fetchedReportList);
        console.log(fetchedReportList);
      } else {
        setReportList([]);
        errors.addError("Error retreiving report list");
      }
      setListChanged(false);
      setIsLoading(false);
    };

    getReportList();
  }, [listChanged]);

  const reloadList = () => {
    setListChanged(true);
  };

  return {
    isLoading,
    errors,
    reportList,
    reloadList,
  };
}
