import { useState } from "react";
import { Button, FormControl, FormSelect } from "react-bootstrap";
import {
  convertValueToZ,
  convertZToValue,
  getNumberFormatByType,
} from "../../../helpers/statsHelperFunctions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function ScoreDescriptionSubScore({
  subScoreDescription,
  scoreDescriptionChangeHandler,
  deleteButtonClickHandler,
}) {
  const [scoreName, setScoreName] = useState(
    subScoreDescription.name === "Subtest Score" ? "" : subScoreDescription.name
  );

  const handleNameChange = (value) => {
    setScoreName(value);
    scoreDescriptionChangeHandler({
      ...subScoreDescription,
      name: value === "" ? "Score" : value,
    });
  };

  const handleTypeSelectChange = (type) => {
    const score = convertZToValue(subScoreDescription.z, type);
    scoreDescriptionChangeHandler({
      ...subScoreDescription,
      score,
      type,
    });
  };

  const handleScoreChange = (value) => {
    scoreDescriptionChangeHandler({
      ...subScoreDescription,
      z: convertValueToZ(value, subScoreDescription.type),
      score: value,
    });
  };

  const handleDeleteButtonClick = () => {
    deleteButtonClickHandler(subScoreDescription.id);
  };

  const handleVisibleChange = (e) => {
    e.stopPropagation();
    scoreDescriptionChangeHandler({
      ...subScoreDescription,
      visible: !subScoreDescription.visible,
    });
  };

  return (
    <div className="d-flex">
      <div className="m-1">
        <FormControl
          type="text"
          size="sm"
          placeholder="Subtest Score Name"
          autoFocus
          value={scoreName}
          onChange={(e) => handleNameChange(e.target.value)}
          onFocus={(e) => e.target.select()}
          onClick={(e) => e.stopPropagation()}
          onKeyUp={(e) => {
            e.preventDefault();
          }}
        />
      </div>
      <div className="m-1 flex-grow-1">
        <FormSelect
          size="sm"
          onChange={(e) => handleTypeSelectChange(e.target.value)}
          value={subScoreDescription.type}
        >
          <option value="Standard">Standard</option>
          <option value="Scaled">Scaled</option>
          <option value="Percentile">Percentile</option>
          <option value="t">t-Score</option>
        </FormSelect>
      </div>
      <div className="m-1 flex-shrink-1" style={{ width: "4rem" }}>
        <FormControl
          type="number"
          size="sm"
          step={
            getNumberFormatByType(
              convertZToValue(subScoreDescription.z, subScoreDescription.type),
              subScoreDescription.type
            ).step
          }
          value={
            getNumberFormatByType(
              convertZToValue(subScoreDescription.z, subScoreDescription.type),
              subScoreDescription.type
            ).value
          }
          onChange={(e) => handleScoreChange(e.target.value)}
          onFocus={(e) => e.target.select()}
          onClick={(e) => e.stopPropagation()}
        />
      </div>
      <div className="px-1">
        <Button variant="light" onClick={handleVisibleChange}>
          <FontAwesomeIcon
            icon={`fa-regular ${
              subScoreDescription.visible ? "fa-eye" : "fa-eye-slash"
            }`}
          />
        </Button>
      </div>
      <div className="p-1">
        <Button
          size="sm"
          variant="danger"
          onClick={(e) => {
            handleDeleteButtonClick();
            e.stopPropagation();
          }}
        >
          <FontAwesomeIcon icon="fa-regular fa-trash-can" />
        </Button>
      </div>
    </div>
  );
}
