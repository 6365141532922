import { useEffect, useState } from "react";

import { useAuth } from "../../hooks/useAuth";
import { useLocation, useNavigate } from "react-router-dom";
import useStayLoggedIn from "../../hooks/useStayLoggedIn";
import useOkModal from "../../hooks/useOkModal";

import {
  Button,
  Container,
  Form,
  FormControl,
  FormFloating,
  FormLabel,
} from "react-bootstrap";
import LoadingSpinner from "../LoadingSpinner";

export default function Login() {
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const { state } = useLocation();

  const { auth } = useAuth();
  const [modal, showModal] = useOkModal();
  const navigate = useNavigate();
  useStayLoggedIn(auth);

  useEffect(() => {
    if (auth.user) {
      navigate(state?.path || "/dashboard/open-report");
    }
  }, [auth.user]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const { error, message } = await auth.login({ userName, password });
      if (error) {
        showModal("Error", message);
      }
    } catch (res) {
      showModal("Error", "Error logging in");
    }
  };

  return (
    <>
      {auth.isLoading ? (
        <LoadingSpinner />
      ) : (
        <div className="d-flex h-100 w-100 justify-content-center align-items-center">
          <div
            className="d-flex flex-column flex-shrink-1 mt-4 mb-auto p-2 shadow rounded"
            style={{ width: "30rem" }}
          >
            <Container className="mx-auto my-2 w-50">
              <h3 className="text-center">Login</h3>
            </Container>
            <Container className="pb-2">
              <Form onSubmit={handleSubmit}>
                <FormFloating className="mb-2">
                  <FormControl
                    id="userName"
                    required={true}
                    placeholder="User Name"
                    onChange={(e) => setUserName(e.target.value)}
                  />
                  <FormLabel htmlFor="userName">User Name</FormLabel>
                </FormFloating>
                <FormFloating className="mb-2">
                  <FormControl
                    type="password"
                    required={true}
                    placeholder="Password"
                    id="password"
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <FormLabel htmlFor="password">Password</FormLabel>
                </FormFloating>
                <Button type="submit">Login</Button>
              </Form>
            </Container>
          </div>
        </div>
      )}
      {modal}
    </>
  );
}
