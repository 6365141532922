import { useRef, useState } from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
} from "react-bootstrap";

export default function useOkModal() {
  const [isModalVisibible, setIsModalVisible] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalText, setModalText] = useState("");
  const modalCb = useRef(() => {});

  const modal = (
    <Modal
      show={isModalVisibible}
      onHide={() => setIsModalVisible(false)}
      backdrop="static"
    >
      <ModalHeader closeButton>
        <ModalTitle>{modalTitle}</ModalTitle>
      </ModalHeader>
      <ModalBody>{modalText}</ModalBody>
      <ModalFooter>
        <Button
          variant="primary"
          onClick={() => {
            setIsModalVisible(false);
            modalCb.current();
          }}
        >
          OK
        </Button>
      </ModalFooter>
    </Modal>
  );

  const showModal = (title, text, cb = () => {}) => {
    modalCb.current = cb;
    setModalTitle(title);
    setModalText(text);
    setIsModalVisible(true);
  };

  return [modal, showModal];
}
