import useReportList from "../../hooks/useReportList";

import { useNavigate } from "react-router-dom";
import { deleteReport } from "../../api/report";
import { useAuth } from "../../hooks/useAuth";
import ReportTable from "../Dashboard/ReportTable/ReportTable";

import { Container, Spinner } from "react-bootstrap";
import LoadingSpinner from "../LoadingSpinner";

export default function OpenReport({
  setDashboardReportId,
  setActiveNavKey,
  currentReportId,
}) {
  const navigate = useNavigate();
  const { auth } = useAuth();

  const { isLoading, errors, reportList, reloadList } = useReportList();

  const handleOpenReport = (id) => {
    console.log(`handleOpenReport: ${id}`);
    setDashboardReportId(id);
    setActiveNavKey("current-report");
    navigate("/dashboard/current-report", {
      state: { reportId: id },
    });
  };

  const handleDeleteReport = async (id) => {
    try {
      await deleteReport(id, auth);
      console.log(id);
      console.log(currentReportId);
      if (currentReportId === id) {
        setDashboardReportId("");
      }
      reloadList();
    } catch (res) {
      //TODO
    }
  };

  return (
    <div className="d-flex h-100" style={{ width: "60rem" }}>
      {errors?.length > 0 && <div>Error: {errors.join(", ")}</div>}
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <Container>
          <ReportTable
            reportList={reportList}
            openReport={handleOpenReport}
            deleteReport={handleDeleteReport}
            isTemplateList={false}
          />
        </Container>
      )}
    </div>
  );
}
