import { useNavigate } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";
import AccountPageItem from "./AccountPageItem";
import { Button } from "react-bootstrap";
import { updateUser } from "../../api/user";

export default function Account() {
  const { auth } = useAuth();
  const navigate = useNavigate();

  const handleLogoutButtonClick = async () => {
    await auth.logout();
    navigate("/");
  };

  const updateFirstName = async (value) => {
    const previousValue = auth.user.firstName;
    auth.user.firstName = value;
    try {
      await updateUser(auth.user, auth);
    } catch (err) {
      auth.user.firstName = previousValue;
    }
  };

  const updateLastname = async (value) => {
    const previousValue = auth.user.lastName;
    auth.user.lastName = value;
    try {
      await updateUser(auth.user, auth);
    } catch (err) {
      auth.user.lastName = previousValue;
    }
  };

  const updateOrganization = async (value) => {
    const previousValue = auth.user.organization;
    auth.user.organization = value;
    try {
      await updateUser(auth.user, auth);
    } catch (err) {
      auth.user.organization = previousValue;
    }
  };

  return (
    <>
      {auth.user && (
        <div className="d-flex justify-content-center h-100 w-100">
          <div
            className="d-flex flex-column justify-content-between mt-4 mb-auto p-4 shadow rounded"
            style={{ width: "30rem" }}
          >
            <AccountPageItem
              label="User Name:"
              value={auth.user.userName}
              isEditable={false}
            />
            <AccountPageItem
              label="Email:"
              value={auth.user.email}
              isEditable={false}
            />
            <AccountPageItem
              label="Account Type:"
              value={auth.user.accountType}
              isEditable={false}
            />
            <AccountPageItem
              label="First Name:"
              value={auth.user.firstName}
              isEditable={true}
              editHandler={updateFirstName}
            />
            <AccountPageItem
              label="Last Name:"
              value={auth.user.lastName}
              isEditable={true}
              editHandler={updateLastname}
            />
            <AccountPageItem
              label="Organization:"
              value={auth.user.organization}
              isEditable={true}
              editHandler={updateOrganization}
            />

            <div className="d-flex">
              <Button
                className="mx-auto"
                variant="secondary"
                onClick={handleLogoutButtonClick}
              >
                Logout
              </Button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
